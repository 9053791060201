<template>
  <div class="home bg" ref="main">
    <div class="head">双十一清单</div>
    <div class="name">
      <div class="name-left">
        <p>我的昵称：</p>
        <input type="text" v-model="nickname" placeholder="请输入您的昵称">
      </div>
      <!-- <div class="name-right">
        <p>删除</p>
        <p @click="selecAll">全选</p>
      </div> -->
    </div>
    <div>
      <div class="commodity" v-for="(item,index) in list" :key="index">
        <div class="checked">
          <img src="../assets/name.png" alt="">
          <img src="../assets/price.png" alt="">
          <img src="../assets/password.png" alt="">
        </div>
        <div class="com-name">
          <p>宝贝:</p>
          <p>价格:</p>
          <p>口令:</p>
        </div>
        <div class="com-content">
          <input type="text" placeholder="请输入宝贝名称" v-model="item.name">
          <input type="text" placeholder="请输入宝贝价格" v-model="item.price">
          <input type="text" placeholder="请输入宝贝口令" v-model="item.password">
          <div class="delete bg" @click="deletebtn(index)"></div>
        </div>
      </div>
    </div>
    <div class="increase bg" @click="increase"></div>
    <div class="btn bg" @click="generate"></div>
    <div class="nick" v-if="nick">
      <div class="modal">请完善信息</div>
    </div>
    <div class="nick" v-if="sensitive">
      <div class="modal" style="width:4rem">{{nickstr}}</div>
    </div>
  </div>
</template>

<script>
// import {sensitivewords} from '../api/index.js'
export default {
  name: 'Home',
  data(){
    return{
      list: [
        {
          name:"",
          price:"",
          password:""
        }
      ],
      nickname:"",
      nick:false,
      sensitive:false,
      nickstr:""
    }
  },
  components: {
  },
  created(){
    if( this.$route.query.info =="info"){
      let obj = JSON.parse(window.localStorage.getItem('shoplist'))
      this.list = obj.data
      this.nickname = obj.nickname
    }
  },
  methods:{
    generate(){
      if(this.nickname==""){
        this.nick = true
        setTimeout(() => {
          this.nick = false
        }, 2000);
        return
      }
      if(this.list.length==0){
        this.sensitive = true
        this.nickstr = "至少输入一件宝贝"
        setTimeout(() => {
          this.sensitive = false
        }, 2000);
        return
      }
      if(this.list.length ==1 && (this.list[0].name=="" && this.list[0].price=="" && this.list[0].password=="")){
        this.nick = true
        setTimeout(() => {
          this.nick = false
        }, 2000);
        return
      }
      let str = this.nickname
      for(var i=0;i<this.list.length;i++){
        str += this.list[i].name
        if(this.list[i].name=="" || this.list[i].price=="" || this.list[i].password==""){
          if(this.list[i].name=="" && this.list[i].price=="" && this.list[i].password==""){
            this.list.splice(i,1)
          }else{
            this.nick = true
            setTimeout(() => {
              this.nick = false
            }, 2000);
            return
          }
        }else{
          console.log(11)
        }
        // if(this.list[i].name=="" || this.list[i].price=="" || this.list[i].password==""){
        //   this.nick = true
        //   setTimeout(() => {
        //     this.nick = false
        //   }, 2000);
        //   return
        // }
      }
      window.$.ajax({
      type: "get",
      url: "https://api.xiaoxxx.com/sensitive",
      data: {
        word: str
      },
      dataType: "jsonp",
      success: (res)=>{
        // console.log(res)
        if(res.data.words.length==0){
          // console.log("111")
          let obj = {
            "data":this.list,
            "nickname":this.nickname
          }
          // console.log(lists)
          window.localStorage.setItem("shoplist",JSON.stringify(obj))
          this.$router.push("/my")
        }else{
          // console.log("2222")
          this.sensitive = true
          this.nickstr = "您输入的内容包含敏感词"
            setTimeout(() => {
              this.sensitive = false
            }, 2000);
            return
        }
      }
    });
      
    },
    increase(){
      this.list.push({name:"",price:"",password:""})
        setTimeout(() => {
          // console.log(this.$refs.main.scrollTop)
          // console.log(document.body.scrollHeight)
          // this.$refs.main.scrollTop(1000)
          document.documentElement.scrollTop = 200000;
          document.body.scrollTop = 20000000;
          // console.log(this.$refs.main.scrollTop)
        }, 1);
    },
    deletebtn(index){
      this.list.splice(index,1)
      // console.log()
    }
  }
      
}
</script>
<style scoped>
.nick{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.nick .modal{
  width: 2.5rem;
  height: 0.5rem;
  font-size: 0.3rem;
  font-weight: bold;
  margin:  auto;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  /* bottom: 2rem; */
  top: 0;
  bottom: 0;
  color: white;
  border-radius: 5px;
  line-height: 0.5rem;
  left: 0;
  right: 0;
}
  input{
    background-color: #eae5df;
  }
  .home{
    padding: 0.79rem 0.26rem;
    /* overflow: hidden; */
    box-sizing: border-box;
  }
  .home .btn{
    height: 0.92rem;
    width: 3.57rem;
    background-image: url('../assets/btn1.png');
    margin: 1rem auto 0;
  }
  .head{
    font-size: 0.6rem;
    /* font-weight: bold; */
    margin-bottom: 1rem;
    font-family: puhui;
  }
  .name{
    display: flex;
    font-size: 0.16rem;
    justify-content: space-between;
    border-bottom: solid #425b6c 0.04rem;
    padding-bottom: 0.14rem;
    margin-bottom: 0.54rem;
  }
  .name .name-left{
    display: flex;
    /* margin-left: 0.7rem; */
  }
  .name .name-left p{
    font-size: 0.35rem;
    font-weight: bold;
    font-family: puhui;
  }
  .name .name-left input{
    border: none;
    border-bottom: dashed 0.04rem #425b6c;
    width: 4rem;
    outline: none;
    padding-left: 0.3rem;
    font-style:puhui1;
    color: #425b6c;
    font-size: 0.44rem;
    border-radius: 0;
  }
  ::-webkit-input-placeholder {
    color: #8e8e8e;
    font-size: 0.35rem;
    font-style:puhui1;
    /* font-weight: bolder; */
}
  /* .name .name-right{
    margin-right: 0.2rem;
    font-size: 0.2rem;
  }
  .name .name-right p:first-of-type{
    color: red;
  } */
  
  .commodity{
    width: 100%;
    display: flex;
    font-size: 0.3rem;
    margin-bottom: 0.4rem;
    /* padding: 0 0.2rem 0.1rem; */
    /* justify-content: space-between; */
    box-sizing: border-box;
    border-bottom: dashed #425b6c 0.02rem;
  }
  .commodity .checked{
    /* width: 0.5rem; */
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0.17rem 0;
  }
  .commodity .checked img{
    width: 0.35rem;
    height: 0.3rem;
    margin-bottom: 0.5rem;
  }
  .commodity .com-name{
    width: 1rem;
  }
  .commodity .com-name p{
    height: 0.6rem;
    margin-bottom: 0.3rem;
    line-height: 0.6rem;
    font-weight: bold;
    font-family: puhui;
  }
  .commodity .com-content{
    width: 5.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .commodity .com-content .delete{
    text-align: right;
    background-image: url('../assets/deletebtn.png');
    width: 0.57rem;
    height: 0.28rem;
  }
  .commodity .com-content input{
    width: 100%;
    height: 0.5rem;
    margin-bottom: 0.36rem;
    outline: none;
    border: none;
    border-bottom: solid 0.01rem #425b6c;
    font-style:puhui1;
    font-size: 0.44rem;
    color: #425b6c;
    border-radius: 0;
  }
  .increase{
    background-image: url('../assets/btn2.png');
    width: 3.3rem;
    height: 0.4rem;
    margin: 0 auto;
  }
</style>